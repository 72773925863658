/* .App {
    font-family: sans-serif;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  
  button {
    border: none;
    background-color: white;
    padding: 0.5rem 1rem;
  }
  
  .texteditor {
    width: 40rem;
  }
  
  .DraftEditor-root {
    border: 2px solid #eee;
    margin: 2rem 0;
    border-radius: 0.5rem;
  }
  
  .DraftEditor-editorContainer {
    padding: 1.5rem;
    overflow: auto;
    height: 20rem;
  }
  
  .public-DraftEditor-content {
    min-height: 20rem;
  }
  
  .save {
    background-color: #3ed3d2;
    color: white;
    border-radius: 0.5rem;
  }
  