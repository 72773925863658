.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.css-1d3ml05-MuiPopper-root-MuiTooltip-popper {
  height: 10%;
}

.css-viryjt-MuiPopper-root-MuiTooltip-popper {
  height: 10%;
}

.long-title h3 {
  font-weight: 200;
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
}

.button {
  cursor: pointer;
  display: block;
  font-size: 1.3em;
  box-sizing: content-box;
  margin: 20px auto 0px;
  width: 90%;
  padding: 15px 15px;
  border-radius: 24px;
  border-color: transparent;
  background-color: white;
  box-shadow: 0px 16px 60px rgba(78, 79, 114, 0.1);
  box-shadow: 0px 16px 60px rgba(78, 79, 114, 0.08);
  position: relative;
}

.icon {
  height: 25px;
  width: 25px;
  margin-right: 0px;
  position: absolute;
  left: 30px;
  align-items: center;
}

@import url("https://cdn.syncfusion.com/ej2/material.css");

/* {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color';
} */

.rbc-day-slot .rbc-time-slot {
  border: unset;
}
.rbc-timeslot-group:nth-child(4n + 1),
.rbc-timeslot-group:nth-child(4n + 2),
.rbc-timeslot-group:nth-child(4n + 3),
.rbc-timeslot-group:nth-child(4n + 4) {
  /* or 4n+1 */
  background-color: #fefefe;
  border-width: 0px;
  margin: 0;
  padding: 0;
  border-color: black;
}

.rbc-timeslot-group:nth-child(4n + 1) {
  border-top: 1px solid #ccc;
}

html,
body,
body > div {
  /* the react root */
  margin: 0;
  padding: 0;
}

h2 {
  margin: 0;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  /* margin: 1.5em 0; */
  margin: 0.5em 0;
  padding: 0;
}

b {
  /* used for event dates/times */
  margin-right: 3px;
}

.demo-app {
  display: flex;
  min-height: 100%;
  font-size: 14px;
}

.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
  /* padding: 2em; */
  padding: 1em 2em;
}

.demo-app-main {
  flex-grow: 1;
  padding: 3em;
}

.fc {
  /* the calendar root */
  max-width: 1000px;
  margin: 0 auto;
}

@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";

